import {

    makeAutoObservable, toJS,

} from "mobx";
import dbstore from "./dbstore";
import moment from "moment";
import _ from "lodash";
import { message } from "antd";
class Store {



    constructor() {
        makeAutoObservable(this, {

        });
    }

    limit = 10
    skip = 0
    getMoreCount = 0;
    pulledCount = 0;
    pullingCount = -1;
    searchString = '';
    setSearchString = async (val) => {
        this.searchString = val;
        this.getMoreCount = 0;
        this.pullingCount = -1
        this.skip = 0;
        console.log(this.searchString);
        await this.getPatientList(true);
    }
    getPatientList = async (replace = false) => {
        dbstore.setLoading(true)
        dbstore.checkSession()

        if (this.pullingCount >= this.getMoreCount) {
            return
        }
        this.pullingCount = this.getMoreCount;
        var path = '/admin/patients';
        var options = {
            method: "get",

            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        // options['cache'] = "no-cache"

        var res = await fetch(
            `${dbstore.endpoint}${path}?limit=${this.limit}&skip=${this.skip}&search=${this.searchString}`,
            options,

        );
        res = await res.json();
        console.log(res);
        var temp = replace ? res : this.patientList.concat(res);

        this.patientList = []
        Object.assign(this.patientList, temp);
        dbstore.setLoading(false)
    }

    onScrollEndReached = () => {
        this.getMoreCount++;
        this.skip = this.getMoreCount * this.limit;
        this.getPatientList();
    }

    selectPatient = (_id) => {
        console.log(_id);
        var pat = _.find(this.patientList, { _id: _id });

        this.selectedPatientDetails._id = pat._id;
        this.selectedPatientDetails.name = pat.name;
        this.selectedPatientDetails.email = pat.email;
        this.selectedPatientDetails.phone = pat.phone;
        this.selectedPatientDetails.status = pat.status || 'active'
        //join address
        this.selectedPatientDetails.address = Object.values(pat.address).join(',');
        var CompilanceForms = []
        var mapping = {
            autoRefill: 'Auto Refill',
            autoDelivery: 'Auto Delivery',
            hipaa: 'HIPPA',
            autoPay: 'Auto Pay/CC',
            prescriptionTransfer: 'Prescription Transfer',
            pillPackEnrollment: 'Pill Pack Enrollment',
            digitalSignature: 'Digital Signature',

        }
        for (var key in mapping) {
            console.log(key);
            if (pat.compliance[key]) {
                CompilanceForms.push({
                    title: mapping[key],
                    signed: pat.compliance[key].signed
                })
            }
        }


        this.selectedPatientDetails.CompilanceForms = CompilanceForms;
    }



    patientDetails = {
        email: '',
        phone: '',
        address: ''
    }

    patientList = [

    ]


    getPatientRx = async (id) => {
        if (!id) {
            id = this.selectedPatientDetails._id
        }
        dbstore.checkSession()

        var path = '/admin/patient/rx';
        var options = {
            method: "get",

            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        options['cache'] = "no-cache"

        var res = await fetch(
            `${dbstore.endpoint}${path}?patid=${id}`,
            options,

        );
        res = await res.json();
        console.log(res);
        var temp = res
        for (var i = 0; i < temp.length; i++) {

            temp[i].address = temp[i].address ? Object.values(temp[i].address).join(',') : ''
            temp[i].name = _.capitalize(temp[i].name)
            temp[i].lastUpdate = moment(temp[i].lastUpdate).format('DD MMM, YYYY')
        }
        this.patientRx = []
        Object.assign(this.patientRx, temp);
        dbstore.setLoading(false)
    }

    patientRx = []

    setSelectedPatient(id) {
        console.log(id);
    }
    setPatientDetails(key, value) {
        this.patientDetails[key] = value;
    }

    handleSearch(context) {
        console.log(context);
    }
    handleFilter(context) {
        console.log(context);
    }
    handleNav(nav) {
        console.log(nav);
    }
    handleAddPatient() {
        console.log('Add Patient');
    }


    getPatientVitals = async (id) => {
        console.log(this.selectedPatientDetails)
        if (!id) {
            id = this.selectedPatientDetails._id
        }
        dbstore.checkSession()


        var path = '/admin/patient/vitals';
        var options = {
            method: "get",

            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        options['cache'] = "no-cache"

        var res = await fetch(
            `${dbstore.endpoint}${path}?patid=${id}`,
            options,

        );
        res = await res.json();
        console.log(res);

        var vitalsavg = res.vitalAvg;
        var vitalData = res.vitalData;

        if (vitalsavg.length == 0) {

            const vitalArr = ['bloodPressure', 'bloodGlucose', 'temperature', 'weight', 'oxygenLevels']

            for (var i = 0; i < vitalArr.length; i++) {
                this.selectedPatientDetails.patientVitals[vitalArr[i]].systolic = 'NA';
                this.selectedPatientDetails.patientVitals[vitalArr[i]].diastolic = 'NA';
                this.selectedPatientDetails.patientVitals[vitalArr[i]].value = "NA";

            }

        }
        for (var i = 0; i < vitalsavg.length; i++) {

            this.selectedPatientDetails.patientVitals[vitalsavg[i]._id].systolic = vitalsavg[i].systolic;
            this.selectedPatientDetails.patientVitals[vitalsavg[i]._id].diastolic = vitalsavg[i].diastolic;
            this.selectedPatientDetails.patientVitals[vitalsavg[i]._id].value = vitalsavg[i].value;

        }
        console.log(vitalData, 'vitalData');

        var bpData = _.filter(vitalData, (o) => { return o._id.type == 'bloodPressure' });
        console.log(bpData, 'bpData');
        var bpSystolic = {
            data: _.map(bpData, 'systolic'),
            labels: _.map(bpData, '_id.date'),
            name: 'bloodPressure',
        }
        console.log(bpSystolic, 'bpSystolic');

        var bpDiastolic = {
            data: _.map(bpData, 'diastolic'),
            labels: _.map(bpData, (d) => { return moment(d._id.date).format('MMM DD') }),
            name: 'bloodPressure',
        }
        this.selectedPatientDetails.patientVitals['bloodPressure'].chartData['systolic'] = bpSystolic;
        this.selectedPatientDetails.patientVitals['bloodPressure'].chartData['diastolic'] = bpDiastolic;

        var weightData = _.filter(vitalData, (o) => { return o._id.type == 'weight' });
        var weight = {
            data: _.map(weightData, 'value'),
            labels: _.map(weightData, (d) => { return moment(d._id.date).format('MMM DD') }),
            name: 'weight',
        }
        this.selectedPatientDetails.patientVitals['weight'].chartData = weight;

        var olData = _.filter(vitalData, (o) => { return o._id.type == 'oxygenLevels' });
        var ol = {
            data: _.map(olData, 'value'),
            labels: _.map(olData, (d) => { return moment(d._id.date).format('MMM DD') }),
            name: 'oxygenLevels',
        }
        this.selectedPatientDetails.patientVitals['oxygenLevels'].chartData = ol;

        var tempData = _.filter(vitalData, (o) => { return o._id.type == 'temperature' });
        var temp = {
            data: _.map(tempData, 'value'),
            labels: _.map(tempData, (d) => { return moment(d._id.date).format('MMM DD') }),
            name: 'temperature',
        }
        this.selectedPatientDetails.patientVitals['temperature'].chartData = temp;

        var bgData = _.filter(vitalData, (o) => { return o._id.type == 'bloodGlucose' });
        var bg = {
            data: _.map(bgData, 'value'),
            labels: _.map(bgData, (d) => { return moment(d._id.date).format('MMM DD') }),
            name: 'bloodGlucose',
        }
        this.selectedPatientDetails.patientVitals['bloodGlucose'].chartData = bg;
        console.log(toJS(this.selectedPatientDetails.patientVitals['bloodPressure'].chartData))
    }

    getVitalRecordsMini = async (type, id) => {
        console.log(this.selectedPatientDetails)
        if (!id) {
            id = this.selectedPatientDetails._id
        }
        dbstore.checkSession()

        var path = '/admin/patient/vitalRecords';
        var options = {
            method: "get",

            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        options['cache'] = "no-cache"

        var res = await fetch(
            `${dbstore.endpoint}${path}?patid=${id}&type=${type}&skip=0&limit=5`,
            options,

        );
        res = await res.json();
        console.log(res);
        if (type == 'bloodPressure') {
            var t = _.map(res, (d) => {
                return {
                    systolic: d.vitals.systolic,
                    diastolic: d.vitals.diastolic,
                    date: moment(Number(d.vitals.date)).format('MMM DD, YYYY')
                }
            })
        } else {

            var t = _.map(res, (d) => {
                return {
                    value: d.vitals.value,
                    date: moment(Number(d.vitals.date)).format('MMM DD, YYYY')
                }
            })
        }
        this.vitalRecordsMini = t;
    }

    getVitalRecordsMega = async (type, id) => {
        console.log(this.selectedPatientDetails)
        if (!id) {
            id = this.selectedPatientDetails._id
        }
        dbstore.checkSession()

        var path = '/admin/patient/vitalRecords';
        var options = {
            method: "get",

            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        options['cache'] = "no-cache"

        var res = await fetch(
            `${dbstore.endpoint}${path}?patid=${id}&type=${type}`,
            options,

        );
        res = await res.json();
        console.log(res);
        if (type == 'bloodPressure') {
            var t = _.map(res, (d) => {
                return {
                    systolic: d.vitals.systolic,
                    diastolic: d.vitals.diastolic,
                    date: moment(Number(d.vitals.date)).format('MMM DD, YYYY')
                }
            })
        } else {

            var t = _.map(res, (d) => {
                return {
                    value: d.vitals.value,
                    date: moment(Number(d.vitals.date)).format('MMM DD, YYYY')
                }
            })
        }
        this.vitalRecordsMega = t;
    }

    vitalRecordsMini = []
    vitalRecordsMega = []
    selectedVital = 'bloodPressure';



    getCalendarData = async (id) => {
        console.log(this.selectedPatientDetails)

        if (!id) {
            id = this.selectedPatientDetails._id
        }
        dbstore.checkSession()

        var path = '/admin/patient/calendar';
        var options = {
            method: "get",

            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        options['cache'] = "no-cache"

        var res = await fetch(
            `${dbstore.endpoint}${path}?patId=${id}`,
            options,

        );
        res = await res.json();
        var meetings = res.meetings;
        var event = {}
        var events = []
        for (var i = 0; i < meetings.length; i++) {
            var m = meetings[i]
            event = {
                id: m._id,
                title: "Video Call with " + m.name,
                start: moment(m.time).toDate(),
                end: moment(m.time).add(1, 'h').toDate(),
                backgroundColor: "#8E2DE2",
                allDay: true
            }

            events.push(event)

        }

        var pc = res.pc;
        var colors = {
            null: '#4853A4',
            'taken': "green",
            missed: "orange"
        }
        for (var i = 0; i < pc.length; i++) {
            var p = pc[i]
            event = {
                id: p._id,
                title: "Pill Compliance " + p.rxno,
                start: moment(Number(p.date)).add(Number(p.time.split(":")[0]), 'h').toDate(),
                end: moment(Number(p.date)).add(Number(p.time.split(":")[0]), 'h').add(1, 'h').toDate(),
                backgroundColor: colors[String(p.status)],
                allDay: true

            }

            events.push(event)

        }


        this.calendarEvents = [];
        Object.assign(this.calendarEvents, events)
        console.log(events);
    }

    calendarEvents = []

    setSelectedVital = (val) => {
        this.selectedVital = val;
    }
    selectedBp = 'systolic';

    setSelectedBp = (val) => {
        this.selectedBp = val;
    }
    selectedPatientDetails = {
        id: "1",
        name: "patient 3",
        age: "22",
        gender: "M",
        email: "yashwandhare124@gmail.com",
        phone: "+1 234 567 98",
        address: "124 Villa Clinic Honover, MD 20176",
        homeClinic: "124 Villa Clinic Honover, MD 20176",
        AssignedTechnician: {
            image: "",
            name: "Technician 1",
            email: "yashwandhare124@gmail.com",
            phone: "+1 234 567 98",
        },
        AssignedDoctor: {
            image: "",
            name: "Doctor 1",
            email: "yashwandhare124@gmail.com",
            phone: "+1 234 567 98",
        },
        CompilanceForms: [
            {
                title: "Auto Refill",
                signed: true
            },
            {
                title: "Hippa",
                signed: false
            },
            {
                title: "Auto Delivery",
                signed: true
            },
            {
                title: "Hippa",
                signed: false
            },
            {
                title: "Auto Pay/CC",
                signed: true
            },
            {
                title: "Prescription Transfer",
                signed: false
            },
            {
                title: "Pill Pack Enrollment",
                signed: true
            },
            {
                title: "Digital Signature",
                signed: true
            }
        ],
        patientVitals: {
            bloodPressure: {
                title: "Blood Pressure",
                systolic: 120,
                diastolic: 80,
                unit: "cmHg",
                comment: "Ideal blood pressure",
                record: [
                    {
                        systolic: 132,
                        diastolic: 85,
                        date: "06-Jan-2021"
                    },
                    {
                        systolic: 132,
                        diastolic: 85,
                        date: "06-Jan-2021"
                    },
                    {
                        systolic: 132,
                        diastolic: 85,
                        date: "06-Jan-2021"
                    },
                    {
                        systolic: 132,
                        diastolic: 85,
                        date: "06-Jan-2021"
                    },
                    {
                        systolic: 132,
                        diastolic: 85,
                        date: "06-Jan-2021"
                    },
                    {
                        systolic: 132,
                        diastolic: 85,
                        date: "06-Jan-2021"
                    }
                ],
                chartData: {}
            },
            bloodGlucose: {
                title: "Blood Glucose",
                amount: 118.6,
                unit: "mgdl",
                comment: "80-130 mgdl",
                record: [
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    }
                ],
                chartData: []

            },
            temperature: {
                title: "Tempreature",
                amount: 97,
                unit: "F",
                comment: "5F higher than normal",
                record: [
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    }
                ],
                chartData: []

            },
            weight: {
                title: "Weight",
                amount: 120,
                unit: "lbs",
                comment: "Ideal body weight",
                record: [
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    }
                ],
                chartData: []

            },
            oxygenLevels: {
                title: "Oxygen Level",
                amount: 90,
                unit: "mmHg",
                comment: "Ideal oxygen level",
                record: [
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    },
                    {
                        amount: 110,
                        date: "06-Jan-2021"
                    }
                ],
                chartData: []

            }

        }
    }


    updateObj = {
        email: null,
        phone: null
    }
    setUpdateObj = (key, val) => {
        this.updateObj[key] = val;
    }
    updatePatient = async () => {
        // if (!this.updateObj.email && !this.updateObj.phone) {
        //     message.error('No data changed')
        //     return
        // }
        dbstore.checkSession()

        var obj = {

        };
        var emailChanged = false;
        var phoneChanged = false;
        if (this.updateObj.email || this.updateObj.email == '') {
            if (this.updateObj.email != this.selectedPatientDetails.email) {
                obj.email = this.updateObj.email
                emailChanged = true
            }
        }
        if (this.updateObj.phone || this.updateObj.phone == '') {
            if (this.updateObj.phone != this.selectedPatientDetails.phone) {
                obj.phone = this.updateObj.phone
                phoneChanged = true
            }
        }
        obj.patid = this.selectedPatientDetails._id;


        var raw = obj
        var path = '/admin/patients';

        var res = await fetch(
            `${dbstore.endpoint}${path}`,
            {
                method: "put",
                // cache: "no-cache",
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(raw)
            },

        );
        var status = res.status;

        res = await res.json();

        console.log(res, 'patient update')

        if (status == 200) {
            message.success('Patient updated successfully')
            if (emailChanged) {
                this.selectedPatientDetails.email = obj.email
            }
            if (phoneChanged) {
                this.selectedPatientDetails.phone = obj.phone
            }
        } else {
            message.error("Oops something went wrong")
        }


        this.updateObj.email = null
        this.updateObj.phone = null



        console.log(obj)
    }


    updateActiveStatus = async () => {
        var status;
        dbstore.checkSession()

        if ('status' in this.selectedPatientDetails) {
            status = this.selectedPatientDetails.status == 'active' ? 'inactive' : 'active'
        } else {
            status = 'inactive'
        }

        var obj = {}
        obj.status = status;
        obj.patid = this.selectedPatientDetails._id;

        var raw = obj
        var path = '/admin/patients';

        var res = await fetch(
            `${dbstore.endpoint}${path}`,
            {
                method: "put",
                // cache: "no-cache",
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(raw)
            },

        );
        var s = res.status;

        res = await res.json();

        console.log(res, 'patient update')

        if (s == 200) {
            message.success('Patient activation changed')
            this.selectedPatientDetails.status = status
            var index = _.findIndex(this.patientList, { _id: this.selectedPatientDetails._id })
            this.patientList[index].status = status
        } else {
            message.error("Oops something went wrong")
        }



    }


    createAlert = async (formName) => {
        dbstore.checkSession()



        var obj = {}
        obj.type = 'form';
        obj.userId = this.selectedPatientDetails._id;
        obj.formName = formName;
        var raw = obj
        var path = '/admin/send/notification';

        var res = await fetch(
            `${dbstore.endpoint}${path}`,
            {
                method: "post",
                // cache: "no-cache",
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(raw)
            },

        );
        var s = res.status;

        res = await res.json();

        console.log(res, 'alert created')

        if (s == 200) {
            message.success('Alert sent')
        } else {
            message.error("Oops something went wrong")
        }
    }

}

const patientStore = new Store();
export default patientStore;