import { Col, Row, Layout, Input, Empty, Modal, Button, message } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { Image, DropdownButton, InputGroup, FormControl, Dropdown } from 'react-bootstrap';
import Technician from '../../assets/images/pharmacist.png';
import Clinic from '../../assets/images/XMLID_412_.png';
import Patient from '../../assets/images/patientIco.png';
import Doctor from '../../assets/images/Path 37556.png';
import ListEle from '../common/ListEle';
import Styles from '../Styles/HomeStyle.module.css';
import DrListItem from '../common/DrListItem';
import Msg from '../../assets/animations/drp1/Icon material-email.png';
import Phone from '../../assets/animations/drp1/Icon awesome-phone-alt.png';
import Person from '../../assets/animations/drp1/Icon awesome-map-marked-alt.png';
import Video from '../../assets/animations/drp1/Group 70.png';
import Audio from '../../assets/animations/drp1/Group 71.png';
import Message from '../../assets/animations/drp1/Icon ionic-ios-chatbubbles.png';
import Home from '../../assets/animations/drp1/Icon material-store-mall-directory.png';
import patientStore from '../../dataLayer/patients';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cross from '../../assets/images/cross.png';
import { Link, NavLink } from 'react-router-dom';
import {
  RiBarChart2Fill,
  RiMailLine,
  RiBarcodeLine,
  RiUserFill,
  RiSettings5Fill,
  RiCalendarTodoFill,
  RiHeartPulseFill,
  RiNumbersFill,
} from "react-icons/ri";
import DropInput from '../common/DropInput';
import AddModal from '../modals/AddModal';
import PrescriptionModal from '../modals/PrescriptionModal';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import HelpStore from '../../dataLayer/help';
import { useHistory } from 'react-router-dom';
import homeStore from '../../dataLayer/home';

var box = {
  backgroundColor: "white",
  margin: 10,
  padding: 20,
  borderRadius: 20,
  color: "#43425d",
  boxShadow: "1px 1px 8px 4px #e1e1e1"
};
var first = {
  backgroundColor: "#f4f6f9",
  borderRadius: "1em",
  padding: "1em"
}
const Patients = observer(({ props }) => {
  let history = useHistory();

  const [showedit, setshowedit] = useState(false)
  useEffect(() => {
    patientStore.getPatientList();

  }, [])

  const listInnerRef = useRef();
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // TO SOMETHING HERE
        console.log('Reached bottom');

        patientStore.onScrollEndReached()
      }
    }
  };



  const docDetails = patientStore?.selectedPatientDetails;
  const [sort, setSort] = useState('None');
  const [nav, setNav] = useState([true, false, false]);
  const [show, setShow] = useState(false);
  const [compalert, setcompalert] = useState(false)
  const [selectedComp, setselectedComp] = useState('')
  const [pres, setPres] = useState(false);
  const [navTitle, setnavTitle] = useState(["All", "Active Patients", "InActive Patients"]);
  const handleModal = () => {
    setShow(!show);
  }
  const handlePresModal = () => {
    setPres(!pres);
    patientStore.getPatientRx()
  }
  const handleClickNav = (index) => {
    var temp = [false, false, false];
    temp[index] = true;
    patientStore?.handleNav(navTitle[index]);
    setNav(temp);
  }
  return (
    <div style={Styles?.main}>
      <AddModal
        show={show}
        title={"Add Patient"}
        handleAdd={async () => {
          await homeStore.handleAddPatient(); setShow(false)
        }}
        handleClose={handleModal}
      />
      <PrescriptionModal
        show={pres}
        data={patientStore?.patientRx}
        title={"Active Prescriptions"}
        handleClose={handlePresModal}
      />
      <Row gutter={[32, 32]} style={{ padding: "0em 5em" }}>
        <Col span={24}>
          <div className={Styles.top}>
            <div>
              <h2>Patients</h2>
            </div>
            <div>
              <a onClick={handleModal}>Add Patient</a>
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className={Styles.topnav}>
            <h6 className={nav[0] && Styles.selectednav} onClick={() => handleClickNav(0)}>All</h6>
            {/* <h6 className={nav[1] && Styles.selectednav} onClick={() => handleClickNav(1)}>Active Patients</h6>
            <h6 className={nav[2] && Styles.selectednav} onClick={() => handleClickNav(2)}>Inactive Patients</h6> */}
          </div>
          <Row>
            <Col span={24}>
              <div style={box}>
                <div>
                  <DropInput value={patientStore.searchString} showSelect={false} placeholder="Enter patients name" onChange={(val) => {
                    patientStore.setSearchString(val);
                  }} />
                </div>
                <div onScroll={() => onScroll()} ref={listInnerRef} style={{ height: "50vh", overflowY: "scroll" }}>
                  {
                    patientStore?.patientList?.map((item, index) => {
                      return (

                        <DrListItem item={item} onClick={() => {
                          patientStore.selectPatient(item._id)
                        }} />

                      );
                    })
                  }
                </div>
              </div>
            </Col>
          </Row>

        </Col>
        <Col span={16}>
          <div className={Styles.rightbox} style={box}>
            {patientStore.selectedPatientDetails._id ? <Row>
              <Col className={Styles.leftist} span={12} style={first}>
                <div style={{ display: "flex", flexDirection: "row", }}>
                  <div style={{ background: "rgba(35,208,152,1)", color: "#fff", borderRadius: "50%", width: "3em", height: "3em", fontSize: "1.5em", fontWeight: "bold", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", margin: "0em 1em" }}><div>{docDetails?.name?.[0]}</div></div>
                  <div>
                    <h4>{docDetails?.name}</h4>
                    <div>{docDetails?.age},{docDetails?.gender}</div>
                  </div>
                </div>
                <div style={{ color: "#000" }} className={Styles.topbuts}>
                  <a>
                    <div>
                      <div>
                        <RiNumbersFill size={25} />
                      </div>
                      <div>Reports</div>
                    </div>
                  </a>
                  <NavLink to={"/patientVitals"}>

                    <div>
                      <div>
                        <RiHeartPulseFill size={25} />
                      </div>
                      <div>Vitals</div>
                    </div>
                  </NavLink>

                  <NavLink to="/patientCalendar">
                    <div>
                      <div>
                        <RiCalendarTodoFill size={25} />
                      </div>
                      <div>RxCalenders</div>
                    </div>
                  </NavLink>
                </div>
                <div className={Styles.fields}>
                  <h5>PERSONAL INFORMATION</h5>
                  <div>
                    <div>
                      <RiUserFill size={25} style={{ margin: "0em 1em" }} />
                      <div>{docDetails?.name}</div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div>{docDetails?.age}</div>
                    </div>
                    <div>
                      <div>{docDetails?.gender}</div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <div>
                        <img src={Msg}></img>
                      </div>
                      <div>{docDetails?.email}</div>
                    </div>
                    <div onClick={() => { setshowedit(true) }} className={Styles.edit}>
                      Edit
                    </div>
                  </div>
                  <div>
                    <div>
                      <div>
                        <img src={Phone}></img>
                      </div>
                      <div>{docDetails?.phone}</div>
                    </div>
                    <div onClick={() => { setshowedit(true) }} className={Styles.edit}>
                      Edit
                    </div>
                  </div>
                  <div>
                    <div>
                      <div>
                        <img src={Person}></img>
                      </div>
                      <div>{docDetails?.address}</div>
                    </div>
                    <div onClick={() => { setshowedit(true) }} className={Styles.edit}>
                      Edit
                    </div>
                  </div>
                </div>

                <div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", padding: "1em" }}>
                    {/* <div>
                      <img style={{ margin: "0em .5em", maxWidth: "5em" }} src={Audio}></img>
                    </div> */}
                    <div onClick={async () => {
                      console.log("call", patientStore.selectedPatientDetails._id);
                      await HelpStore.setCurrentUserName(patientStore.selectedPatientDetails.name);
                      await HelpStore.setCurrentUser(patientStore.selectedPatientDetails._id);
                      await HelpStore.setCurrentUserType('patient')
                      history.push('/help')

                    }}>
                      <img style={{ margin: "0em .5em", maxWidth: "5em" }} src={Message}></img>
                    </div>
                    {/* <div>
                      <img style={{ margin: "0em .5em", maxWidth: "5em" }} src={Video}></img>
                    </div> */}
                  </div>
                </div>
              </Col>
              <Col className={Styles.leftist} span={12}>
                <div style={{ margin: "0em 1em" }}>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h5>Assigned Doctor</h5>
                  </div>
                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center", background: "#fff", borderRadius: "1em", border: "1px solid #000", padding: "1em", margin: "1em" }}>
                      <div style={{ display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center" }}>
                        <div style={{ padding: "1em", borderRadius: "2em", background: "#e3e3e3" }}></div>
                        <div style={{ margin: "0em 1em" }}>{docDetails?.AssignedDoctor?.name}</div>
                      </div>
                      <div>
                        <img style={{ maxWidth: "2em", margin: "0em .5em" }} src={Audio}></img>
                        <img style={{ maxWidth: "2em", margin: "0em .5em" }} src={Message}></img>
                        <img style={{ maxWidth: "2em", margin: "0em .5em" }} src={Video}></img>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ margin: "0em 1em" }}>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h5>Assigned Technician</h5>
                  </div>
                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center", background: "#fff", borderRadius: "1em", border: "1px solid #000", padding: "1em", margin: "1em" }}>
                      <div style={{ display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center" }}>
                        <div style={{ padding: "1em", borderRadius: "2em", background: "#e3e3e3" }}></div>
                        <div style={{ margin: "0em 1em" }}>{docDetails?.AssignedTechnician?.name}</div>
                      </div>
                      <div>
                        <img style={{ maxWidth: "2em", margin: "0em .5em" }} src={Audio}></img>
                        <img style={{ maxWidth: "2em", margin: "0em .5em" }} src={Message}></img>
                        <img style={{ maxWidth: "2em", margin: "0em .5em" }} src={Video}></img>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ margin: "0em 1em" }}>
                  <h5>Compliance forms</h5>

                  <div className={Styles.formscom}>
                    {
                      docDetails?.CompilanceForms?.map((item, index) => (
                        <div onClick={() => {
                          if (!item?.signed) {
                            setcompalert(true)
                            setselectedComp(item?.title)
                          }
                        }} className={item?.signed ? Styles.nofill : Styles.fill}>
                          <FontAwesomeIcon size={'2x'} icon={item?.signed ? "check-circle" : "times-circle"} />
                          <div><p style={{ textAlign: 'center', }}>{item?.title}</p></div>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className={Styles.viewsection} style={{ padding: "1em" }}>

                  <div style={{ display: "flex", justifyContent: "space-between", margin: "1em 0em" }}>
                    <h6>Active Prescriptions</h6>
                    <a style={{ background: "rgba(35,208,152,1)", color: "#fff", fontWeight: "bold", padding: ".5em 1em", borderRadius: "2em" }} onClick={handlePresModal}>View</a>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", margin: "1em 0em" }}>
                    <h6>Activity Log</h6>
                    <a style={{ background: "rgba(35,208,152,1)", color: "#fff", fontWeight: "bold", padding: ".5em 1em", borderRadius: "2em" }}>View</a>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", margin: "1em 0em" }}>
                    <h6>Make inactive</h6>
                    <a
                      onClick={() => {
                        patientStore.updateActiveStatus()
                      }}
                      style={{ background: patientStore.selectedPatientDetails?.status == 'active' ? "red" : "green", color: "#fff", fontWeight: "bold", padding: ".5em 1em", borderRadius: "2em" }}>{patientStore.selectedPatientDetails?.status == 'active' ? 'Inactivate' : 'Activate'}</a>
                  </div>
                </div>
              </Col>
            </Row> : <Row style={{ justifyContent: 'center', alignItems: 'center', height: "70vh", }}><Empty /></Row>}
          </div>
        </Col>
      </Row>
      <Modal
        visible={compalert}
        footer={false}
        //  onOk={handleOk} 
        onCancel={() => setcompalert(false)}
      >
        <Row style={{ width: '100%' }} align="middle">
          <Col>
            <h2>Create Alert</h2>
          </Col>
          <Col span={24}>
            <p>Alert patient for incomplete compliance</p>
          </Col>

          <Col style={{ marginTop: 100 }} span={24}>
            <div style={{
              backgroundColor: "#23D098", width: 100, height: 40,
              float: 'right', display: 'flex', justifyContent: 'center', cursor: 'pointer',
              alignItems: 'center', borderRadius: 10
            }}
              onClick={() => {
                patientStore.createAlert(selectedComp)
                setcompalert(false)
              }}
            >
              <p style={{ textAlign: 'center', color: "#fff", marginTop: 10 }}>Submit</p>
            </div>
          </Col>
        </Row>

      </Modal>
      <Modal
        visible={showedit}
        footer={false}
        //  onOk={handleOk} 
        onCancel={() => setshowedit(false)}
      >
        {Object.keys(patientStore.selectedPatientDetails).length > 0 && <Row style={{ width: '100%' }} align="middle">
          <Col span={6} style={{ textAlign: 'right' }}><span>Email:</span></Col>
          <Col span={18}><input
            onChange={(e) => {
              console.log(e.target.value)
              patientStore.setUpdateObj('email', e.target.value)

              // patientStore.selectedPatientDetails.email = e.target.value
            }}

            className="drp-input" value={patientStore.updateObj?.email || patientStore.updateObj?.email == '' ? patientStore.updateObj?.email : patientStore.selectedPatientDetails?.email} placeholder="Email"></input></Col>
          <Col span={6} style={{ textAlign: 'right' }}><span>Phone:</span></Col>
          <Col span={18}><input
            onChange={(e) => {
              console.log(e.target.value)
              patientStore.setUpdateObj('phone', e.target.value)
              // patientStore.selectedPatientDetails.phone = e.target.value
            }}
            className="drp-input" placeholder="Phone" value={patientStore.updateObj?.phone || patientStore.updateObj?.phone == '' ? patientStore.updateObj?.phone : patientStore.selectedPatientDetails?.phone}></input></Col>
          {/* <Col span={6} style={{ textAlign: 'right' }}><span>Address Line:</span></Col> */}
          {/* <Col span={18}><input
            onChange={(e) => {
              console.log(e.target.value)
              patientStore.selectedPatientDetails.address.AddressLine = e.target.value
            }}
            className="drp-input" placeholder="Address Line" value={patientStore.selectedPatientDetails?.address?.AddressLine || ''}></input></Col> */}
          {/* <Col span={6} style={{ textAlign: 'right' }}><span>City:</span></Col>
        <Col span={18}><input
          onChange={(e) => {
            console.log(e.target.value)
            patientsStore.comp3Data.address.City = e.target.value
          }}
          className="drp-input" placeholder="City" value={patientsStore.comp3Data?.address?.City || ''}></input></Col>
        <Col span={6} style={{ textAlign: 'right' }}><span>State Code:</span></Col>
        <Col span={18}><input
          onChange={(e) => {
            console.log(e.target.value)
            patientsStore.comp3Data.address.StateCode = e.target.value
          }}
          className="drp-input" placeholder="State Code" value={patientsStore.comp3Data?.address?.StateCode || ''}></input></Col>
        <Col span={6} style={{ textAlign: 'right' }}><span>Zip Code:</span></Col>
        <Col span={18}><input
          onChange={(e) => {
            console.log(e.target.value)
            patientsStore.comp3Data.address.ZipCode = e.target.value
          }}
          className="drp-input" placeholder="Zip Code" value={patientsStore.comp3Data?.address?.ZipCode || ''}></input></Col> */}
          <Col span={24} >
            <Button
              onClick={async () => {
                var obj = toJS(patientStore.selectedPatientDetails);
                console.log(obj)
                patientStore.updatePatient()
                setshowedit(false)
                // var res = await dbstore.updatePatientDetails(obj)
                // if (res) {
                //   message.success("Update Successfull!")
                // } else {
                //   message.error('Oops! something went wrong')
                // }
              }}
              className="drp-btn-green" style={{ display: 'block', width: 125, marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}>Submit</Button>
          </Col>

        </Row>}
      </Modal>

    </div >
  );
});
export default Patients;