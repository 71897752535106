import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Input,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import ReconcilerStore from "../../../dataLayer/reconcilerStore";
import { ReloadOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Text, Title } = Typography;

const ReconcilerDataView = observer(() => {
  const [patientName, setPatientName] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState([
    moment().subtract(1, "day"),
    moment(),
  ]);

  function getData() {
    let requestBody = {
      startDate: selectedDateRange[0].format("YYYY-MM-DD"),
      endDate: selectedDateRange[1].format("YYYY-MM-DD"),
    }
    if(patientName && patientName.trim() != '') {
      requestBody.patientName = patientName; 
    }
    ReconcilerStore.reconcileJobsData(requestBody);
  }

  function onDateChange(value) {
    setSelectedDateRange(value);
  }

  function renderJobStatus(jobKey, jobTypeName) {
    const jobResult =
      ReconcilerStore.reconcilerJobsData[jobKey][jobTypeName]["result"];
    const textColor = jobResult === "PASS" ? "success" : "danger";
    return <Text type={textColor}>{jobTypeName.toUpperCase()}</Text>;
  }

  function renderPanelHeader(jobKey) {
    return (
      <Row align="middle">
        <Col span={12}>
          <Text>{jobKey} </Text>
        </Col>

        <Col span={11} style={{ textAlign: "end" }}>
          <Space>
            {Object.keys(ReconcilerStore.reconcilerJobsData[jobKey]).map(
              (eachJobTypeName) => renderJobStatus(jobKey, eachJobTypeName)
            )}
          </Space>
        </Col>
        <Col span={1} style={{ textAlign: "end" }}>
          <ReloadOutlined title="Reload job" onClick={() => console.log("reload job")} />
        </Col>
      </Row>
    );
  }

  function renderReconcilerData() {
    return Object.keys(ReconcilerStore.reconcilerJobsData).map((eachJobKey) => (
      <Panel
        showArrow={false}
        header={renderPanelHeader(eachJobKey)}
        key={eachJobKey}
      >
        <pre>
          {JSON.stringify(
            ReconcilerStore.reconcilerJobsData[eachJobKey],
            null,
            2
          )}
        </pre>
      </Panel>
    ));
  }

  return (
    <Row>
      <Col span={24}>
        <Space>
          <RangePicker
            allowClear={false}
            format="YYYY-MM-DD"
            value={selectedDateRange}
            onChange={onDateChange}
          />
          <Input placeholder="Patient name" onChange={(e) => setPatientName(e.target.value)} />
          <Button type="primary" onClick={getData}>
            Get Data
          </Button>
        </Space>
        {ReconcilerStore.isJobDataLoading ? (
          <Spin style={{ margin: "25% 50%" }} />
        ) : (
          <>
            <Row
              style={{
                margin: "60px 0px",
              }}
            >
              <Title level={3}>
                {`Total Jobs : ${
                  Object.keys(ReconcilerStore.reconcilerJobsData).length
                }`}
              </Title>
              <Col span={24} style={{ maxHeight: "800px", overflowY: "auto" }}>
                <Collapse accordion bordered={false}>
                  {renderReconcilerData()}
                </Collapse>
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
});

export default ReconcilerDataView;
